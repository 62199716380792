import React from "react"
import { Link, graphql } from "gatsby"
//import Img from "gatsby-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Bio from "../components/bio"
import he from "he"
import { formatPostDescriptionSeo } from "../utils/index"

const post = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <Seo
        title={frontmatter.title}
        description={formatPostDescriptionSeo(html)}
      />
      <h2 className="font-bold text-center mb-5">
        {he.decode(frontmatter.title)}
      </h2>
      <span className="block text-sm text-gray-400 text-center">
        {frontmatter.date}
      </span>
      <div className="divider"></div>
      <article className="mt-5" dangerouslySetInnerHTML={{ __html: html }} />
      <div className="divider"></div>
      <Bio />
      <Link
        to="/blog"
        title="Back"
        className="block text-lg font-bold text-center my-7"
      >
        &larr; Back to Blog
      </Link>
      <div className="divider"></div>
    </Layout>
  )
}
export default post

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`
