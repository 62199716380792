import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.title

  return (
    <div className="flex flex-col justify-center items-center">
      <StaticImage
        layout="fixed"
        formats={["AUTO", "WEBP", "AVIF"]}
        src="../images/MK.png"
        width={50}
        height={50}
        quality={95}
        alt="Profile picture"
        className="mb-4"
      />
      {author && (
        <span>
          <span>Written/Curated by {author}</span>
        </span>
      )}
    </div>
  )
}

export default Bio
